import Ad from "./ad";

import React, { useEffect, useState } from "react";

export default function Story012Ad({ pageContext: { ad } }) {
    const [hash, setHash] = useState(null);

    useEffect(() => {
        setHash(window.location.hash);
    }, []);

    const emailSuffixMatch = (hash || "").replace("#", "").match(/^[a-z0-9]+$/);
    const replyUid = emailSuffixMatch ? emailSuffixMatch[0] : null;
    const replyEmail = replyUid
        ? ad.replyEmailTemplate.replace("{}", `.${replyUid}`)
        : null;

    return <Ad pageContext={{ ad, replyEmail, replyUid }} />;
}
